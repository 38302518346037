import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout.js"

export const query = graphql`
  query {
    wpgraphql {
      posts {
        nodes {
          id
          title
          uri
          excerpt(format: RENDERED)
          categories {
            nodes {
              name
            }
          }
          author {
            name
          }
          modified
          date
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const posts = data.wpgraphql.posts.nodes

  return (
    <Layout>
      {posts.map(post => (
        <article key={post.id}>
          <h2>
            <Link
              to={`/blog/${post.uri}`}
              dangerouslySetInnerHTML={{ __html: post.title }}
            />
          </h2>
          <ul className="post-meta">
            <li dangerouslySetInnerHTML={{ __html: "Autor: " + post.author.name }}/>
            <li dangerouslySetInnerHTML={{ __html: "Kategorie: " + post.categories.nodes.name }}/>
            <li dangerouslySetInnerHTML={{ __html: "Verfasst: " + post.date }}/>
            <li dangerouslySetInnerHTML={{ __html: "Geändert: " + post.modified }}/>
          </ul>
          <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
        </article>
      ))}
    </Layout>
  )
}

export default Blog